html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  font-family: 'Basier Circle', Roboto, -apple-system, 'Helvetica Neue', Helvetica,
    'Segoe UI', Arial, sans-serif;
}

body > div {
  margin: 0;
  width: 100%;
  height: 100%;
}
